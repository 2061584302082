/* General Styles */

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.members-page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.table-container {
  flex-grow: 1;
  overflow-y: auto;
}

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

header, footer {
  background-color: #003366;
  color: white;
  padding: 1rem 0;
  text-align: center;
}

main {
  padding: 2rem;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Open Sans', sans-serif;
  color: #003366;
  margin-top: 0;
}

p {
  line-height: 1.6;
  color: #333;
}

/* Buttons */
button, .btn {
  background-color: #003366;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 0.9rem;
  transition: background-color 0.3s, transform 0.3s;
}

button:hover, .btn:hover {
  background-color: #002244;
  transform: scale(1.05);
}

button:active, .btn:active {
  background-color: #001122;
}

/* Layout */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 0.5rem 1rem;
  border-bottom: 2px solid #003366;
}

.navbar a {
  color: #003366;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.navbar a:hover {
  background-color: #f4f4f4;
}

.footer {
  background-color: #003366;
  color: white;
  text-align: center;
  padding: 1rem 0;
  position: fixed;
  bottom: 0;
  width: 100%;
}

/* Utility Classes */
.text-center {
  text-align: center;
}

.mt-1 { margin-top: 1rem; }
.mt-2 { margin-top: 2rem; }
.mt-3 { margin-top: 3rem; }
.mb-1 { margin-bottom: 1rem; }
.mb-2 { margin-bottom: 2rem; }
.mb-3 { margin-bottom: 3rem; }

/* Specific Styles for Elements */
.header-logo {
  height: 80px; /* Adjust the height to make the logo bigger */
  margin-right: 20px; /* Add some margin to space it from the nav links */
}

.main-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.link-button {
  display: inline-block;
  background-color: #003366;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.3s, transform 0.3s;
}

.link-button:hover {
  background-color: #002244;
  transform: scale(1.05);
}

.link-button:active {
  background-color: #001122;
}

/* Home Page Specific */
.homepage-container {
  display: flex;
}

.sidebar {
  width: 250px;
  background-color: #fff;
  padding: 1rem;
  border-right: 2px solid #003366;
  height: calc(100vh - 120px); /* Adjust based on your header and footer heights */
}

.main-content {
  flex-grow: 1;
  padding: 2rem;
}

.mb-1 { margin-bottom: 1rem; }